<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  export let onClick;

  // event handler for Pin Task
  function PinTask() {
    dispatch("onPinTask", {
      id: task.id,
    });
  }
  // event handler for Pin Task
  function EditTask() {
    dispatch("onEditTask", {
      task,
    });
    dispatch("onInit", {});
  }
  // event handler for Pin Task
  function RemoveTask() {
    if (confirm("Are you sure?"))
      dispatch("onRemoveTask", {
        id: task.id,
      });
  }

  // event handler for Archive Task
  function ToggleTask(event) {
    //event.target.selectEnd = 0; // fix highlight ux
    dispatch("onToggleTask", {
      id: task.id,
    });
  }

  // Task props
  export let task = {
    id: "",
    description: "",
    done: false,
    pinned: false,
    date_updated: new Date(2021, 0, 1, 9, 0),
  };
  // Reactive declaration (computed prop in other frameworks)
  $: isChecked = task.done;
  $: isPinned = task.pinned;
</script>

<div
  class="list-item {(task.done && 'done') || ''} {(task.pinned && 'pinned') ||
    ''}"
  on:click={onClick}
>
  {#if task.id}
    <label
      class="checkbox"
      on:click={ToggleTask}
      aria-label={`toggleTask-${task.id}`}
    >
      <input type="checkbox" checked={isChecked} disabled name="checked" />
      <span class="checkbox-custom" />
    </label>
    <div class="title">
      <input
        on:blur|preventDefault={EditTask}
        on:keydown={(event) => {
          if (event.key === "Enter") event.target.blur();
        }}
        disabled={isChecked}
        type="text"
        id={task.id}
        bind:value={task.description}
        placeholder="describe the task here"
      />
    </div>
    <div class="actions">
      <button disabled={isChecked} on:click|preventDefault={PinTask}>
        <span class="icon-star" aria-label={`pinTask-${task.id}`} />
      </button>
      <button on:click|preventDefault={RemoveTask}>
        <span class="icon-trash " aria-label={`removeTask-${task.id}`} />
      </button>
    </div>
  {:else}
    <div><label class="checkbox" for="0" /></div>
    <div class="title">
      <slot />
      &emsp;
      <input readonly={true} type="text" value={task.description} />
    </div>
  {/if}
</div>

<style>
  input {
    color: whitesmoke;
  }
  input[type="checkbox"] {
    border: 0.15rem solid darkcyan;
  }
  .title input {
    cursor: pointer;
    width: 95%;
  }
  button {
    background-color: transparent;
    color: whitesmoke;
  }
  button[disabled=""] {
    cursor: not-allowed !important;
    color: #222;
  }
</style>
