<script>
  import Footer from "../Footer.svelte";

  let y = 0;
  export let title = "";
  export let textLinks = [];
  export let socialIcons = [];
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  export let sections = [
    {
      image: "412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp",
      content: "",
      alt: "",
      title: "",
    },
    {
      image: "bc78bb9c-d899-4db6-8682-12b33a19259b.jpg",
      content: "",
      alt: "",
      title: "",
    },
  ];
</script>

{#each sections as section, i}
  <div
    class="hero h-full w-full"
    style={`--y: ${y}px; height: ${sections.length * 100}vh`}
  >
    <picture class={"hero-item w-full h-screen " + "p" + (i + 1)}>
      <source
        srcset={`${API_URL}/assets/${section.image}?width=800`}
        media="(max-width: 420px)"
      />
      <source
        srcset={`${API_URL}/assets/${section.image}?width=1500`}
        media="(max-width: 800px)"
      />
      <source srcset={`${API_URL}/assets/${section.image}`} />
      <img
        class="object-cover object-top w-full"
        src={`${API_URL}/assets/${section.image}`}
        alt={section.alt}
      />
    </picture>
    <div class="hero-item w-full title">
      {(sections[0] === section && title) || ""}
    </div>
  </div>
  <div class="blog-text relative">
    {@html section.content}
  </div>
{/each}

<picture class="hero-item w-full h-screen last">
  <source
    srcset={`${API_URL}/assets/${sections[0].image}?width=800`}
    media="(max-width: 420px)"
  />
  <source
    srcset={`${API_URL}/assets/${sections[0].image}?width=1500`}
    media="(max-width: 800px)"
  />
  <source srcset={`${API_URL}/assets/${sections[0].image}`} />
  <img
    class="object-cover object-top w-full"
    src={`${API_URL}/assets/${sections[0].image}`}
    alt={sections[0].alt}
  />
</picture>

<footer>
  <Footer items={socialIcons} />
  <Footer items={textLinks}>
    <span slot="copy">
      | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
    </span>
  </Footer>
</footer>

<svelte:window bind:scrollY={y} />

<style>
  .parallax {
    background-image: url("img_parallax.jpg");

    height: 100%;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  picture.hero-item {
  }
  picture.hero-item.p1 {
    margin-top: 0;
    will-change: transform;
    transform: translateY(calc(var(--y) * -0.92));
    z-index: 1;
  }

  div.hero-item {
    z-index: 4;
  }

  picture.hero-item.p2 {
    will-change: transform;
    transform: translateY(calc(var(--y) * 0.48));
    z-index: 2;
  }

  .hero-item:nth-child(4) {
    z-index: 4;
  }
  .last {
    padding-bottom: 0;
    z-index: 9;
  }
  .hero-item.title {
    margin-top: 0;
    position: relative;
    top: -100%;
    z-index: 4;
    font-size: calc(35px + 5vw);
    font-weight: bold;
    letter-spacing: 5px;
    color: #fff;
    text-shadow: 0 0 0.75rem #000;
    left: 50%;
    top: 6rem;
    text-align: center;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .blog-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 50px;
    padding-bottom: 4rem;
    font-size: 50px;
    color: #fff;
    background-color: #222;
    text-shadow: 0px -30px 170px 80px #000;
    z-index: 4;
  }
</style>
