<script>
  import TaskList from "./TaskList.svelte";
  import { taskStore, AppStore } from "../../store";
  import DirectusSDK from "@directus/sdk-js";
  import { v4 as uuid } from "uuid";
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  const directus = new DirectusSDK(API_URL);

  async function getUserData(table = "tasks") {
    let data = [];
    try {
      ({ data } = await directus.items(table).read({
        filter: {
          assignee: {
            email: { _eq: $AppStore.user.email },
          },
        },
      }));
      console.info({ data });
    } catch (err) {
      AppStore.setUser(null);
      console.error(err);
      AppStore.setError(err.message || err);
    }
    return data;
  }

  async function setUserData(table = "tasks", data, mode = "update") {
    console.log(table, data);
    let result;
    try {
      switch (mode) {
        case "update":
          result = await directus.items(table)[mode](data.id, data);
          break;
        case "create":
        case "delete":
          result = await directus.items(table)[mode](data);
          break;
      }
    } catch (err) {
      console.error(err);
      AppStore.setError(err.message || err);
    }
    return result;
  }

  async function onPinTask(event) {
    const task = tasks.find((t) => t.id === event.detail.id);
    task.pinned = !task.pinned;
    taskStore.editTask(task);
    if (!unitTest) await setUserData("tasks", task);
  }
  async function onEditTask(event) {
    //console.log(event.detail);
    taskStore.editTask(event.detail);
    if (!unitTest) await setUserData("tasks", task);
  }
  async function onRemoveTask(event) {
    taskStore.removeTask(event.detail.id);
    if (!unitTest) await setUserData("tasks", event.detail.id, "delete");
  }
  async function onAddTask(event) {
    try {
      const task = {
        id: event.detail.id || uuid(),
      };
      taskStore.addTask(task);
      await setUserData("tasks", task, "create");
    } catch (err) {
      console.error(err);
      AppStore.setError(err.message || err);
    }
  }
  async function onToggleTask(event) {
    const task = tasks.find((t) => t.id === event.detail.id);
    task.done = !task.done;
    taskStore.editTask(task);
    if (!unitTest) await setUserData("tasks", task);
  }
  async function onReset(event) {
    taskStore.reset(event.detail);
  }
  export let loading = true;
  export let tasks;
  export let init;
  export let user;
  export let unitTest = false;

  async function onLoad() {
    if (init === "reset" && loading) {
      // load from tasks param or wipe store if empty
      taskStore.reset(tasks);
      loading = false;
    } else if ((init === "init" && loading) || user) {
      // load store from server tasks
      taskStore.reset((tasks = await getUserData("tasks")));
    }
  }

  $: console.log({ loading, tasks, init });
  $: onLoad();
</script>

<div>
  <TaskList
    {tasks}
    {loading}
    on:onPinTask={onPinTask}
    on:onReset={onReset}
    on:onAddTask={onAddTask}
    on:onEditTask={onEditTask}
    on:onRemoveTask={onRemoveTask}
    on:onToggleTask={onToggleTask}
  />
</div>
