<script>
  export let images = [];
  import Image from "./base/Image.svelte";
</script>

{#each images as image}
  <Image
    bind:props={image}
    src={image.filename_disk}
    alt={image.description || ""}
  />
{/each}
