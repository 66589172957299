<script>
  export let open = false;
</script>

<aside
  class="absolute w-full md:w-1/3 h-full bg-gray-200 border-r-2 shadow-lg"
  class:open
>
  <nav class="p-12 text-xl" on:click={() => (open = !open)}>
    <slot />
  </nav>
</aside>
<div
  class="absolute w-full h-screen transparent"
  class:hidden={!open}
  on:click={() => (open = false)}
/>

<style>
  aside {
    left: -100%;
    transition: left 0.3s ease-in;
  }

  .open {
    left: 0;
  }

  nav,
  aside {
    background-color: #222 !important;
    z-index: 9;
    top: 4rem;
    bottom: 0;
    position: fixed;
    text-align: left !important;
  }
</style>
