<script>
  import "./header.css";
  import Logo from "./Logo.svelte";
  import Hamburger from "./Hamburger.svelte";
  import { Link } from "svelte-navigator";

  export let sidebar = false;
</script>

<section
  class="flex justify-between bg-gray-200 items-center text-gray-600 border-b-2"
>
  <nav class="flex">
    <Hamburger bind:open={sidebar} />
    <Link to="/">
      <div on:click={() => (sidebar = false)}>
        <Logo />
      </div>
    </Link>
  </nav>

  <nav class="hidden text-gray-500 sm:block"><slot /></nav>
</section>

<style>
  section,
  section * {
    background-color: #333;
    z-index: 9;
  }
</style>
