<script>
  import "./menu/header.css";
  import Button from "../components/base/Button.svelte";
  import { AppStore } from "../store";
  import { Link } from "svelte-navigator";

  export let items = [];
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  const assetFolder = `${API_URL}/assets/`;

  //$: console.log(mode);
  $: items = items.filter((item) => item.location === "footer");
  //$: console.log(items);
</script>

<nav class="footer">
  <ul>
    {#each items as item}
      {#if item.path}
        <li>
          <a
            href={(item.folder ? item.folder.path : "") + item.path}
            title={item.title}
            target="_blank"
          >
            {#if !item.image}
              <span>{item.title}</span>
            {:else}
              <img
                src={assetFolder + item.image.filename_disk}
                alt={item.image.title}
              />
            {/if}
          </a>
        </li>
      {/if}
      {#if item.page}
        <li>
          <Link to={(item.folder ? item.folder.path : "") + item.page.path}>
            {#if !item.image}
              <span>{item.title}</span>
            {:else}
              <img
                src={assetFolder + item.image.filename_disk}
                alt={item.image.title}
              />
            {/if}
          </Link>
        </li>
      {/if}
    {/each}

    {#if $$slots.copy}<li><slot name="copy" /></li>{/if}
  </ul>
</nav>

<style>
  nav.footer {
    background-color: #333;
    padding: 1em;
    z-index: 10;
  }
  li {
    line-height: 3rem;
  }
  nav ul {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-evenly;
    flex-direction: row;
  }
  span {
    margin: 0 1em;
    line-height: 1rem;
  }
  nav {
    position: sticky;
    bottom: 0;
    justify-content: center;
    font-size: small;
  }
  nav span {
    flex-direction: column;
    margin: 0 1em;
    text-decoration: underline;
  }
  img {
    height: 40px;
    margin: 0 0.5rem;
  }

  nav ul {
    justify-content: center;
  }
  a {
    margin: 0;
    padding: 0;
  }
</style>
