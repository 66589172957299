<script>
  import Carousel from "../base/Carousel.svelte";

  import Footer from "../Footer.svelte";
  import Gallery from "../Gallery.svelte";

  // TODO: move to toolbelt
  const sortDesc = (a, b) => {
    if (!a.sort && a.sort !== 0) return 1;
    if (b.sort === null) return -1;
    if (a.sort === b.sort) return 0;
    return a.sort < b.sort ? 1 : -1;
  };

  let y = 0;
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  let url = `${API_URL}/assets/`;
  export let sections = [
    {
      sort: 10,
      background: {
        filename_disk: "bc78bb9c-d899-4db6-8682-12b33a19259b.jpg",
      },
      html: `Scroll up and down to really get the feeling of how Parallax Scrolling works.`,
      subtitle: "This is where it gets weird",
      title: "It's a bunny",
    },
    {
      background: {
        filename_disk: "412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp",
      },
      html: `Parallax scrolling is a web site trend where the background content is moved at a different speed than the foreground content while scrolling.`,
      subtitle: "It's pretty sweet yeah?",
      title: "Parallax Scrolling",
    },
    {
      sort: 7,
      background: {
        filename_disk: "412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp",
      },
      html: `The image was actually repeated twice, but it looks like just one image!`,
      subtitle: "This is actually some other section",
      title: "Scrolling is fun",
    },
  ];

  export let id;
  export let sort;
  export let title = "";
  export let subtitle = "";
  //export let path = "";
  export let html = "";
  export let background;
  export let galleries = [];
  let currentGallery;
  let gallery;
  let blocks = [];
  export let textLinks = [];
  export let socialIcons = [];
  $: sections = sections.sort(sortDesc);
  $: sections = sections.map((sect) => {
    blocks =
      (blocks.length && blocks) || sect?.content_id?.html?.match(/###(.*?)###/);
    currentGallery =
      (blocks.length > 1 && blocks[0].includes("###") && blocks[1]) ||
      currentGallery;
    console.log({ blocks, currentGallery });
    blocks =
      (blocks[0].includes("###") && sect?.content_id?.html?.split(blocks[0])) ||
      blocks;
    console.log({ blocks, currentGallery });
    // currentGallery = galleries.filter(
    //   (gallery) =>
    //     sect?.content_id?.html.split(gallery.title)[0].includes("###") &&
    //     sect?.content_id?.html.split(gallery.title)
    // );
    //console.log({ currentGallery });
    return sect;
  });
  $: gallery = gallery || galleries.find((g) => g.title === currentGallery);
  $: console.log({ id, sort, sections, blocks, currentGallery, gallery });
</script>

<div
  class="image primary"
  style={background
    ? `background-image: url(${url + background?.filename_disk})`
    : undefined}
>
  <div class="caption">
    <span class="anton text-white h1">
      {title}
    </span>
  </div>
</div>
<div class="content relative">
  <h2 class="text-center">
    {subtitle}
  </h2>
  <p>
    {@html html}
  </p>
</div>

{#each sections as section, i}
  <div
    class="image h-full w-full"
    style={section?.content_id?.background
      ? `background-image: url(${
          url + section?.content_id?.background?.filename_disk
        })`
      : undefined}
  >
    <div class="caption">
      <span class="anton text-white h1">
        {section?.content_id?.title || "missing title"}
      </span>
    </div>
  </div>
  <div class="content relative" class:even={i % 2 === 0}>
    <h2 class="text-center">
      {section?.content_id?.subtitle || "missing subtitle"}
    </h2>
    <p>
      {#if blocks.length > 1}
        {console.log("render", { blocks }) || "render"}
        {@html blocks[0]}
        {#if gallery}
          <Carousel>
            <Gallery bind:this={currentGallery} />
          </Carousel>
        {/if}
        {@html blocks[1]}
      {:else}
        {@html section?.content_id?.html}
      {/if}
    </p>
  </div>
{/each}

<div
  class="image primary"
  style={background
    ? `background-image: url(${url + background?.filename_disk})`
    : undefined}
>
  <div class="caption">
    <span class="anton text-white h1">
      {title}
      <h2 class="text-center text-white subtitle-message">
        {subtitle}
      </h2>
    </span>
  </div>
</div>

<footer>
  <Footer items={socialIcons} />
  <Footer items={textLinks}>
    <span slot="copy">
      | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
    </span>
  </Footer>
  <script>
    $: document
      .getElementById("video")
      .addEventListener(
        "contextmenu",
        (event) => event.preventDefault(),
        false
      );
  </script>
</footer>

<svelte:window bind:scrollY={y} />

<style>
  .h1 {
    font-size: 3em;
  }
  .even h2 {
    color: #111;
  }
  .anton {
    text-shadow: 0 0 0.75rem white;
    padding: 0.2rem;
    margin: 0.2rem;
  }
  .text-white,
  h2.text-white {
    text-shadow: 0 0 0.75rem #111;
    opacity: 1;
  }

  .image {
    position: relative;
    opacity: 0.75;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
  }
  .image.primary {
    min-height: 100vh;
  }

  .content {
    padding: 5em 1em;
  }
  .content,
  .content h2 {
    background-color: #333;
    color: whitesmoke;
  }
  .content.even,
  .content.even h2 {
    background-color: whitesmoke;
    color: #333;
  }

  .caption {
    width: 100%;
    text-align: center;
    color: #000;
    display: table;
    margin: auto;
    height: 100vh;
    vertical-align: middle;
  }

  .caption > span {
    display: table-cell;
    vertical-align: middle;
  }
  .caption > span > h2 {
    line-height: 1em;
    letter-spacing: 1.5px;
  }

  /* Turn off parallax scrolling for phones */
  /* @media only screen and (max-device-width: 500px) {
    .image {
      background-attachment: scroll;
    }
  } */
</style>
