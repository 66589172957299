<script>
  import { useNavigate, useLocation } from "svelte-navigator";
  import { AppStore } from "../../store";

  const navigate = useNavigate();
  const location = useLocation();

  $: if (!$AppStore.user) {
    navigate("/login", {
      state: { from: $location.pathname },
      replace: true,
    });
  }
</script>

{#if $AppStore.user}
  <slot />
{/if}
