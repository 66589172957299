<script>
  import { useNavigate, useLocation } from "svelte-navigator";
  import { AppStore } from "../store";
  import DirectusSDK from "@directus/sdk-js";
  import ErrorMsg from "../components/base/ErrorMsg.svelte";
  import Input from "../components/base/Input.svelte";
  import Button from "../components/base/Button.svelte";
  let email = "";
  let password = "";

  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  const directus = new DirectusSDK(API_URL);
  const navigate = useNavigate();
  const location = useLocation();

  // we need to authenticate if data is private

  async function login(event) {
    try {
      if (!email) throw "Invalid credentials";
      if (!password) throw "Invalid credentials";

      await directus.auth.login({
        cookie: true,
        email,
        password,
      });
      AppStore.setUser({ email, password });
      //await new Promise((resolve) => setTimeout(resolve, 200));
      navigate("/tasks", {
        state: { from: $location.pathname },
        replace: true,
      });
    } catch (err) {
      AppStore.setUser(null);
      AppStore.setError("Invalid credentials");
    }
    console.log($AppStore);
    console.log({ email, password });
  }
  async function load() {
    const result = await directus.auth.refresh();
    AppStore.setUser(result && result.data);
    if ($AppStore.user)
      return navigate("/tasks", {
        state: { from: $location.pathname },
        replace: true,
      });
  }
  $: console.log($AppStore);
  $: load();
</script>

<section>
  {#if $AppStore.error}
    <ErrorMsg error={$AppStore.error} />
  {:else}
    <div class="wrapper">
      <h1 class="title-page">Log in</h1>
    </div>
    <div class="wrapper center">
      <form>
        <Input
          props={{
            title: "Username",
            autocomplete: "username",
            type: "text",
            required: "",
          }}
          bind:value={email}
        />
        <Input
          props={{
            title: "Password",
            type: "password",
            autocomplete: "current-password",
            required: "",
          }}
          bind:value={password}
        /><Button primary on:click={login} label="Submit" />
      </form>
    </div>
  {/if}
</section>

<style>
  section {
    margin: auto;
    max-width: 980px;
    width: 100%;
  }
  form {
    text-align: center;
    margin: auto;
    max-width: 360px;
  }
  .wrapper {
    border-color: #333;
    background-color: #333;
    display: block;
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
    margin-top: 2em;
  }
</style>
