<script>
  export let props; // spread into img
  export let src = "";
  export let alt = "";
  export let width = "100%";
  export let height = "100%";
  export let classes = ""; // for img tag only
  export let type;
  function getMime(fileext) {
    switch (fileext) {
      case "jpg":
      case "jfif":
      case "pjpeg":
      case "pjp":
        return "image/jpeg";
      case "tif":
        return "image/tiff";
      case "svg":
        "image/svg+xml";
      default:
        return `image/${fileext}`;
    }
  }
  let style; // for height and width only
  let fileext = ""; // to set metatype
  $: style = width ? `width: ${width}; ` : "";
  $: style += height ? `height: ${height}; ` : "";
  $: src = src || props?.src || "";
  $: fileext = src.split(".")[1];
  $: type = type || getMime(fileext);
</script>

<picture {style}>
  <source srcset={src + "?width=840"} media="(max-width: 420px)" {type} />
  <source srcset={src + "?width=1600"} media="(max-width: 800px)" {type} />
  <source srcset={src} {type} />
  <img {...props} {alt} {src} {style} {height} class={classes} />
</picture>

<style>
  img,
  picture {
    padding: 0;
    margin: 0;
  }
</style>
