<script>
  import Footer from "../Footer.svelte";
  export let socialIcons = [];
  export let textLinks = [];
  export let type = "image/webp";

  let y = 0;
  export let imageUrl = "412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp";
  export let alt = "";
  export let title = "Marbles On Stream";
  export let height = 150;
  let image;
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  $: image = `${API_URL}/assets/${imageUrl}`;
  $: style = `min-height: ${height}px;`;
</script>

<div class="hero" style={`--y: ${y}px`}>
  <div class="cover" {style}>
    <div class="cover parallax" {style} />
    <picture class="hero-item parallax">
      <source srcset={image + "?width=800"} media="(max-width: 420px)" {type} />
      <source
        srcset={image + "?width=1500"}
        media="(max-width: 800px)"
        {type}
      />
      <source srcset={image} {type} />
      <img src={image} {alt} {style} />
    </picture>
  </div>

  <div class="hero-item parallax title">{title}</div>
</div>
<div class="body">
  <slot name="content" />
</div>
<footer>
  <Footer items={socialIcons} />
  <Footer items={textLinks}>
    <span slot="copy">
      | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
    </span>
  </Footer>
</footer>
<svelte:window bind:scrollY={y} />

<style>
  .hero {
    width: 100%;
  }

  .hero img,
  .cover {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
  }

  .cover .cover {
    position: absolute;
    top: calc(4rem + 2px);
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.45) 100%,
      rgba(255, 255, 255, 0.25) 0%
    );
    z-index: 4;
  }

  .hero .hero-item {
    position: absolute;
    width: 100%;
  }

  .hero .parallax {
    position: fixed;
  }

  .hero .hero-item.parallax.title {
    z-index: 5;
    font-size: calc(1rem + 2vw);
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 0.75rem #000;
    left: 50%;
    top: 5rem;
    text-align: center;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: auto;
    max-width: 980px;
    margin: auto;
    font-size: calc(1rem + 1.25vw);
    padding: 4rem 0;
    color: #fff;
    background-color: #222;
    text-shadow: 0px -30px 170px 80px #000;
    z-index: 4;
  }

  @media screen and (max-device-width: 400px) {
    .hero img,
    .cover {
      width: 100vw !important;
    }
    .hero .hero-item.parallax.title,
    .body {
      font-size: large;
    }
  }
  @media screen and (min-device-width: 400px) {
    .hero img,
    .cover {
      height: calc(100vh);
    }
  }
</style>
