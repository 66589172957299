<script>
  import "./menu/header.css";
  import Button from "../components/base/Button.svelte";
  import { AppStore } from "../store";
  import { useNavigate, useLocation, Link } from "svelte-navigator";
  import DirectusSDK from "@directus/sdk-js";
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  const directus = new DirectusSDK(API_URL);

  const navigate = useNavigate();
  const location = useLocation();

  function onLogin(event) {
    navigate("/login", {
      state: { from: $location.pathname },
      replace: true,
    });
  }

  async function onLogout(event) {
    try {
      await directus.auth.logout();
      await new Promise((resolve) => setTimeout(resolve, 200));
      localStorage.removeItem("directus_access_token");
      localStorage.removeItem("directus_access_token_expires");
    } catch (err) {
      //AppStore.setError(err.message || err);
    }
    AppStore.setUser(null);
    onLogin(event);
  }

  async function load() {
    try {
      const result = await directus.auth.refresh();
      AppStore.setUser(result && result.data);
    } catch (err) {
      AppStore.setUser(null);
      AppStore.setError(err.message || err);
      localStorage.removeItem("directus_access_token");
      localStorage.removeItem("directus_access_token_expires");
    }
  }

  let meta;
  directus.server.info().then((t) => (meta = t.data.project));
  $: title = (meta && meta.project_name + " " + meta.project_descriptor) || "";
  $: loggedIn = $AppStore.loggedIn;
  $: load();
</script>

{#if !loggedIn}
  <Button primary on:click={onLogin} label="Log in" />
{/if}
{#if loggedIn}
  <Button on:click={onLogout} label="Log out" />
{/if}
