<script>
  export let onClick;
  // animate
  export let index = 0; // not animated when falsey
  export let animated = !index || false; // not animated when true
  export let step = 0;
  export let ms = 0;
  export let gridCols = "auto 1fr auto";
  console.info("input", { index, ms });
  ms = ms || (step + index) / ((index || 1) / 10);

  const delay = () => new Promise((resolve) => setTimeout(resolve, ms));
  $: console.info("delays", { index, ms });
  //$: ms = index ? getDelay(index) : ms;
  $: delay().then(() => (!animated ? (animated = true) : false));
</script>

<div
  class:hidden={!animated}
  class:animated
  class:tdDropInLeft={index && index % 2 === 1}
  class:tdDropInRight={index && index % 2 === 0}
  class="item"
  style={`grid-template-columns: ${gridCols};`}
  on:click={onClick}
>
  <slot />
</div>

<style>
  .item {
    line-height: 3rem;
    display: grid;
    justify-items: stretch;
  }
  .item.hidden {
    display: none;
  }
  .item {
    background: none;
  }
  .item:hover {
    font-weight: bold;
    cursor: pointer;
  }
</style>
