<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import LoadingRow from "../base/LoadingRow.svelte";
  import { taskStore } from "../../store";
  import Task from "./Task.svelte";
  import RowItem from "../base/RowItem.svelte";
  export let loading;

  // function fibonacci(num = 1) {
  //   var a = 1,
  //     b = 0,
  //     temp;

  //   while (num >= 0) {
  //     temp = a;
  //     a = a + b;
  //     b = temp;
  //     num--;
  //   }

  //   return b;
  // }
  // let index = 1;
  // const step = 10;
  // function getDelay(i = 0) {
  //   const mod = i % step;
  //   console.info({ index });
  //   if (mod !== 0 && i < step) {
  //     index = i - mod; // the last round index
  //   } else {
  //     index = i; // a round number
  //   }
  //   console.info({ index, mod });

  //   return Math.ceil(step * index * 5) + mod * step;
  // }

  // event handler for New Task
  function AddTask() {
    dispatch("onAddTask", {});
  }

  $: count = $taskStore.length;
  $: noTasks = count === 0;
  $: emptyTasks = noTasks && !loading;
  $: tasksInOrder = [
    ...$taskStore.filter((t) => t.pinned),
    ...$taskStore.filter((t) => !t.pinned),
  ];
</script>

{#if emptyTasks}
  <div class="wrapper">
    <div class="wrapper-message">
      <span class="icon-check" />
      <div class="title-message">You have no tasks</div>
      <div class="subtitle-message">
        Relax & maybe add something for tomorrow
      </div>
    </div>
  </div>
{:else if loading && noTasks}
  <div class="list-items">
    <LoadingRow />
    <LoadingRow />
    <LoadingRow />
    <LoadingRow />
    <LoadingRow />
  </div>
{:else}
  {#each tasksInOrder as task, t}
    <Task
      bind:task
      on:onPinTask
      on:onToggleTask
      on:onRemoveTask
      on:onEditTask
    />
  {/each}
{/if}
{#if emptyTasks || ($taskStore[$taskStore.length - 1] && $taskStore[$taskStore.length - 1].description)}
  <Task task={{ description: "New Task" }} onClick={AddTask}>
    <button aria-label={`addTask`}>
      <span class="icon-add" />
    </button>
  </Task>
{/if}

<style>
  button {
    background-color: transparent;
    vertical-align: middle;
  }
  div span {
    margin-top: 0.5em;
  }
  .wrapper {
    border-color: #333;
    background-color: #333;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 2em;
    padding: 12em 0 12em 0;
  }
  .list-items {
    border-color: #222;
    background-color: #666;
    display: block;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 2em;
    color: whitesmoke;
  }
</style>
