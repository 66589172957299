<script>
  import Footer from "../Footer.svelte";
  import { Link } from "svelte-navigator";

  // TODO: move to toolbelt
  const sortDesc = (a, b) => {
    if (!a.sort && a.sort !== 0) return 1;
    if (b.sort === null) return -1;
    if (a.sort === b.sort) return 0;
    return a.sort < b.sort ? 1 : -1;
  };

  let y = 0;
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  let url = `${API_URL}/assets/`;
  export let sections = [
    {
      sort: 10,
      background: {
        filename_disk: "bc78bb9c-d899-4db6-8682-12b33a19259b.jpg",
      },
      html: `Scroll up and down to really get the feeling of how Parallax Scrolling works.`,
      subtitle: "This is where it gets weird",
      title: "It's a bunny",
    },
    {
      background: {
        filename_disk: "412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp",
      },
      html: `Parallax scrolling is a web site trend where the background content is moved at a different speed than the foreground content while scrolling.`,
      subtitle: "It's pretty sweet yeah?",
      title: "Parallax Scrolling",
    },
    {
      sort: 7,
      background: {
        filename_disk: "412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp",
      },
      html: `The image was actually repeated twice, but it looks like just one image!`,
      subtitle: "This is actually some other section",
      title: "Scrolling is fun",
    },
  ];

  //export let path = "";
  export let background = "412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp";
  export let textLinks = [];
  export let socialIcons = [];
  $: sections = sections.sort(sortDesc);
  $: console.log({ sections });
</script>

<div
  class="image primary"
  style={background ? `background-image: url(${url + background})` : undefined}
>
  <div class="caption">
    <span class="anton text-white h1"> Games </span>
  </div>
</div>
<div class="content" />

{#each sections as section, i}
  <Link to={"/games/" + section?.path}>
    <div
      class="image h-full w-full cursor-pointer"
      style={section?.background?.filename_disk
        ? `background-image: url(${url + section?.background?.filename_disk})`
        : undefined}
    >
      <div class="caption">
        <span class="anton text-white text-center h1">
          {section?.title || "missing title"}
          {#if section?.subtitle}
            <br /><span class="text-center text-white subtitle-message">
              {section?.subtitle}
            </span>
          {/if}
        </span>
      </div>
    </div>
    <div class="content" class:even={i % 2 === 0}>
      <!-- <p>
         {@html section?.html}
      </p> -->
    </div>
  </Link>
{/each}

<footer>
  <Footer items={socialIcons} />
  <Footer items={textLinks}>
    <span slot="copy">
      | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
    </span>
  </Footer>
</footer>

<svelte:window bind:scrollY={y} />

<style>
  .h1 {
    font-size: 3em;
  }
  .subtitle-message {
    font-size: 1.25rem;
  }
  .even {
    color: #111;
  }
  .anton {
    text-shadow: 0 0 0.75rem white;
    padding: 0.2rem;
    margin: 0.2rem;
  }
  .text-white {
    text-shadow: 0 0 0.75rem #111;
    opacity: 1;
  }

  .image {
    opacity: 0.75;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
  }
  .image.primary {
    min-height: 100vh;
  }

  .content {
    padding: 1em;
  }
  .content {
    background-color: #333;
    color: whitesmoke;
  }
  .content.even {
    background-color: whitesmoke;
    color: #333;
  }

  .caption {
    width: 100%;
    text-align: center;
    color: #000;
    display: table;
    margin: auto;
    height: 100vh;
    vertical-align: middle;
  }

  .caption > span {
    display: table-cell;
    vertical-align: middle;
  }
  .caption > span > span {
    line-height: 1em;
    letter-spacing: 1.5px;
  }
  /* Turn off parallax scrolling for phones */
  /* @media only screen and (max-device-width: 500px) {
    .image {
      background-attachment: scroll;
    }
  } */
</style>
