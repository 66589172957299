<script>
  import Carousel from "@beyonk/svelte-carousel";
  let carousel;
  export let perPage = 1;

  function enter() {
    carousel.pause();
  }

  function leave() {
    carousel.resume();
  }
</script>

<Carousel
  {perPage}
  bind:this={carousel}
  on:mouseenter={enter}
  on:mouseleave={leave}
>
  <slot />
</Carousel>
