<script>
  export let props;
  export let value = props.value;
  export let events = {};
</script>

<label>
  {props.title}
  <input
    {...props}
    bind:value
    on:click={events.click}
    on:keydown={events.type}
    on:focus={events.focus}
    on:blur={events.blur}
  />
</label>

<style>
  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-basis: 100%;
  }
  input {
    margin: 1em;
    padding: 1em;
    flex-basis: 100%;
  }
</style>
