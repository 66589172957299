<script>
  let y = 0;
  export let title = "";
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  export let image1 = {
    sm: `${API_URL}/assets/bc78bb9c-d899-4db6-8682-12b33a19259b.jpg?width=800`,
    md: `${API_URL}/assets/bc78bb9c-d899-4db6-8682-12b33a19259b.jpg?width=1500`,
    lg: `${API_URL}/assets/bc78bb9c-d899-4db6-8682-12b33a19259b.jpg`,
    png: `${API_URL}/assets/bc78bb9c-d899-4db6-8682-12b33a19259b.jpg`,
    alt: ``,
  };
</script>

<div class="hero w-full h-screen" style={`--y: ${y}px`}>
  <picture class="hero-item w-full fixed">
    <source srcset={image1.sm} media="(max-width: 420px)" type="image/webp" />
    <source srcset={image1.md} media="(max-width: 800px)" type="image/webp" />
    <source srcset={image1.lg} type="image/webp" />
    <img
      class="object-cover object-top w-full"
      src={image1.png}
      alt={image1.alt}
    />
  </picture>
  <div class="hero-item fixed title">{title}</div>
</div>
<div class="blog-text">
  <slot name="content" />
</div>
<picture class="hero-item w-full">
  <source srcset={image1.sm} media="(max-width: 420px)" type="image/webp" />
  <source srcset={image1.md} media="(max-width: 800px)" type="image/webp" />
  <source srcset={image1.lg} type="image/webp" />
  <img
    class="object-cover object-top w-full"
    src={image1.png}
    alt={image1.alt}
  />
</picture>
<slot name="footer" />
<svelte:window bind:scrollY={y} />

<style>
  @media (max-width: 800px) {
    .hero {
      height: 50vw;
    }
  }

  .hero .hero-item:nth-child(1) {
    will-change: transform;
    transform: translateY(calc(var(--y) * -0.92));
    z-index: 1;
  }

  .hero .hero-item:nth-child(2) {
    z-index: 2;
  }

  .hero .hero-item:nth-child(3) {
    will-change: transform;
    margin-top: 100vh;
    padding-bottom: 100vh;
    transform: translateY(calc(var(--y) * -0.48));
    z-index: 3;
  }

  .hero .hero-item:nth-child(4) {
    z-index: 4;
  }

  .hero .hero-item.title {
    z-index: 4;
    font-size: calc(35px + 5vw);
    font-weight: bold;
    letter-spacing: 5px;
    color: #fff;
    text-shadow: 0 0 0.75rem #000;
    left: 50%;
    top: 6rem;
    text-align: center;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .blog-text {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 50px;
    padding-bottom: 4rem;
    font-size: 50px;
    color: #fff;
    background-color: #222;
    text-shadow: 0px -30px 170px 80px #000;
    z-index: 4;
  }
</style>
