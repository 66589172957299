<script>
  import { AppStore } from "../../store";
</script>

<div class="wrapper">
  <span class="icon-face-sad" />
  <div class="title-message">Oh no!</div>
  <div class="subtitle-message">Something went wrong</div>
  <br />
  <div class="subtitle-message">{$AppStore.error || ""}</div>
  <br />
</div>

<style>
  div span.icon-face-sad {
    color: #8a7d7e;
  }
  .wrapper {
    display: block;
    position: static;
    top: initial;
    border-color: #333;
    background-color: #333;
    padding: 12em 0 12em 0;
    margin: 0 1em 0 1em;
  }
  .icon-face-sad {
    font-size: 10em;
  }
</style>
