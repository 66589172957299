<script>
  import ErrorMsg from "../components/base/ErrorMsg.svelte";
  import TaskBox from "../components/tasks/TaskBox.svelte";

  export let error;
  export let tasks;
  export let init;
  export let loading = true;
  export let user;
  //$: console.log({ tasks, init });
</script>

{#if error}
  <ErrorMsg {error} />
{:else}
  <TaskBox bind:tasks {init} {loading} bind:user />
{/if}
