import { writable } from "svelte/store";
import DirectusSDK from "@directus/sdk-js";
const API_URL = process.env.NODE_ENV
  ? "https://www.mostest.pixelbypixelcanada.com/api"
  : "http://localhost:8055";

console.log({ API_URL });
const directus = new DirectusSDK(API_URL);

const TaskBox = () => {
  // Creates a new writable store populated with some initial data
  const { subscribe, update } = writable([]);
  return {
    subscribe,
    reset: (list) => update((tasks) => (tasks = list)),
    addTask: (task) => update((tasks = []) => [...tasks, task]),
    removeTask: (id) =>
      update((tasks = []) => tasks.filter((task) => task.id !== id)),
    editTask: (task) =>
      update((tasks) =>
        tasks.map((t) => (task.id === t.id ? { ...t, ...task } : t))
      ),
  };
};

export const scoreStore = writable([]);
export const taskStore = TaskBox();

// Store to handle the app state
const AppState = () => {
  const { subscribe, update } = writable({
    error: "",
    loading: true,
    loggedIn: !!directus.auth.token,
    user: null,
  });
  return {
    subscribe,
    init: async () => {
      let list = [];
      return update(() => list);
    },
    setUser: (user) =>
      update((store) => {
        store.user = user;
        store.loggedIn = !!user;
        store.loading = !user;
        return store;
      }),
    setError: (err) =>
      update((store) => {
        if (typeof err !== "string") return store;
        store.error = err;
        console.warn(err);
        return store;
      }),
  };
};

export const AppStore = AppState();

export const helpers = {
  graphQuery: async (query, variables) => {
    let { data } = await directus.axios.post("/graphql", {
      query,
      variables,
    });
    return data?.data;
  },
  getItems: async (table, options) => {
    try {
      const { data } = await directus.items(table).read(options);
      console.info({ data });
      return data || [];
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
};
