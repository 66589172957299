<script>
  import Footer from "../Footer.svelte";

  const sortDesc = (a, b) => {
    if (!a.sort && a.sort !== 0) return 1;
    if (b.sort === null) return -1;
    if (a.sort === b.sort) return 0;
    return a.sort < b.sort ? 1 : -1;
  };
  let y = 0;
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  let url = `${API_URL}/assets/`;
  export let sections = [
    {
      sort: 10,
      background: {
        filename_disk: "bc78bb9c-d899-4db6-8682-12b33a19259b.jpg",
      },
      html: "Scroll up and down to really get the feeling of how Parallax Scrolling works.",
      subtitle: "This is where it gets weird",
      title: "It's a bunny",
    },
    {
      background: {
        filename_disk: "412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp",
      },
      html: `Parallax scrolling is a web site trend where the background content is
      moved at a different speed than the foreground content while scrolling.
      Nascetur per nec posuere turpis, lectus nec libero turpis nunc at, sed
      posuere mollis ullamcorper libero ante lectus, blandit pellentesque a,
      magna turpis est sapien duis blandit dignissim. Viverra interdum mi magna
      mi, morbi sociis. Condimentum dui ipsum consequat morbi, curabitur aliquam
      pede, nullam vitae eu placerat eget et vehicula. Varius quisque non
      molestie dolor, nunc nisl dapibus vestibulum at, sodales tincidunt mauris
      ullamcorper, dapibus pulvinar, in in neque risus odio. Accumsan fringilla
      vulputate at quibusdam sociis eleifend, aenean maecenas vulputate, non id
      vehicula lorem mattis, ratione interdum sociis ornare. Suscipit proin
      magna cras vel, non sit platea sit, maecenas ante augue etiam maecenas,
      porta porttitor placerat leo.`,
      subtitle: "It's pretty sweet yeah?",
      title: "Parallax Scrolling",
    },
    {
      sort: 7,
      background: {
        filename_disk: "412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp",
      },
      html: `The image was actually repeated twice, but it looks like just one image!`,
      subtitle: "This is actually some other section",
      title: "Scrolling is fun",
    },
  ];
  function cleanQuery(sections) {
    if (!sections[0]?.content_id) return sections;
    return sections.map((section) => section.content_id);
  }

  export let title = "";
  export let subtitle = "";
  export let textLinks = [];
  export let socialIcons = [];
  let primary = sections[0];
  $: sections = cleanQuery(sections);
  $: sections.sort(sortDesc);
  $: primary = sections[0];
  $: console.log({ primary, sections });
</script>

{#each sections as section, i}
  <div
    class="image h-full w-full"
    class:primary={primary === section}
    style={section?.background
      ? `background-image: url(${url + section?.background?.filename_disk})`
      : undefined}
  >
    <div class="caption">
      <span class="anton text-white h1">
        {primary === section ? title : section.title || "missing title"}
      </span>
      {#if primary === section}
        <h2 class="text-center text-white">
          {subtitle || "missing subtitle"}
        </h2>
      {/if}
    </div>
  </div>
  <div class="content relative" class:even={i % 2}>
    <h2 class="text-center">
      {section.subtitle || "missing subtitle"}
    </h2>
    <p>
      {@html section.html}
    </p>
  </div>
{/each}

<div
  class="image primary"
  style={primary?.background
    ? `background-image: url(${url + primary?.background?.filename_disk})`
    : undefined}
>
  <div class="caption">
    <span class="anton text-white h1">
      {primary.title || title || ""}
    </span>
    <h2 class="text-center text-white">
      {primary.subtitle || "missing subtitle"}
    </h2>
  </div>
</div>

<footer>
  <Footer items={socialIcons} />
  <Footer items={textLinks}>
    <span slot="copy">
      | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
    </span>
  </Footer>
</footer>

<svelte:window bind:scrollY={y} />

<style>
  .h1 {
    font-size: 5em;
  }
  .even h2 {
    color: #111;
  }
  .anton {
    text-shadow: 0 0 0.75rem white;
    font-size: 5em;
    padding: 0.2rem;
    margin: 0.2rem;
  }
  .text-white,
  h2.text-white {
    text-shadow: 0 0 0.75rem #111;
    opacity: 1;
  }

  .image {
    position: relative;
    opacity: 0.75;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
  }
  .image.primary {
    min-height: 100vh;
  }

  .content {
    padding: 5em 1em;
  }
  .content,
  .content h2 {
    background-color: #333;
    color: whitesmoke;
  }
  .content.even,
  .content.even h2 {
    background-color: whitesmoke;
    color: #333;
  }

  .caption {
    position: absolute;
    left: 0;
    top: calc(50% - 5em);
    width: 100%;
    text-align: center;
    color: #000;
  }

  /* Turn off parallax scrolling for phones */
  /* @media only screen and (max-device-width: 500px) {
    .image {
      background-attachment: scroll;
    }
  } */
</style>
