<script>
  import { helpers } from "../../store";
  import LoadingRow from "../base/LoadingRow.svelte";
  import RowItem from "../base/RowItem.svelte";

  export let loading = true;
  export let columns = [""];
  export let gridCols = "auto auto auto";
  export let store;
  export let collection;
  export let options;
  export let delay = 250;

  async function onLoad() {
    if ($store.length) return;
    $store = await helpers.getItems(collection, options);
  }

  //$: console.log({ loading, items });
  $: onLoad();
  $: count = $store.length;
  $: noScores = count === 0;
  $: emptyScores = noScores && !loading;
</script>

{#if emptyScores}
  <span class="icon-check" />
  <div class="title-message">No {collection} found.</div>
  <div class="subtitle-message">
    Nothing was found in the {collection} collection.
  </div>
{:else if loading && noScores}
  <div class="list-items">
    <LoadingRow />
    <LoadingRow />
    <LoadingRow />
    <LoadingRow />
    <LoadingRow />
  </div>
{:else}
  <RowItem {gridCols}>
    {#each columns as col}
      <span class="sect font-bold">{col}</span>
    {/each}
  </RowItem>
  {#each $store as item, i}
    <RowItem index={i + 1} step={delay} {gridCols}>
      {#each columns as col}
        <span class="sect">{item[col.toLowerCase()]}</span>
      {/each}
    </RowItem>
  {/each}
{/if}

<style>
  .list-items {
    border-color: #222;
    background-color: #666;
    display: block;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 2em;
    color: whitesmoke;
  }
  .sect {
    min-width: 75px;
  }
  .sect:first-child {
    text-align: left;
  }
  .sect {
    min-width: 75px;
  }
  .sect:last-child {
    text-align: right;
  }
</style>
