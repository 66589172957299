<script>
  let y = 0;
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  export let title = "";
  export let image = "4df35260-31d3-4fe1-875f-14dadcc54501.jpg"; //"412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp";
  export let image1 = {
    sm: `${API_URL}/assets/${image}?width=860`,
    md: `${API_URL}/assets${image}?width=1500`,
    lg: `${API_URL}/assets/${image}`,
    png: `${API_URL}/assets/${image}`,
    alt: ``,
  };
  export let image2 = {
    sm: `https://res.cloudinary.com/dshzwn3ee/image/upload/w_1500/v1550325902/compress-2-21.webp`,
    md: `https://res.cloudinary.com/dshzwn3ee/image/upload/w_800/v1550325902/compress-2-21.webp`,
    lg: `https://res.cloudinary.com/dshzwn3ee/image/upload/v1550325902/compress-2-21.webp`,
    png: `https://i.ibb.co/VvfFv2T/compress-2.png`,
    alt: ``,
  };
  export let image3 = {
    sm: `https://res.cloudinary.com/dshzwn3ee/image/upload/w_1500/v1550325903/compress-2-2.webp`,
    md: `https://res.cloudinary.com/dshzwn3ee/image/upload/w_800/v1550325903/compress-2-2.webp`,
    lg: `https://res.cloudinary.com/dshzwn3ee/image/upload/v1550325903/compress-2-2.webp`,
    png: `https://i.ibb.co/vxP9dYN/compress-2-2.png`,
    alt: ``,
  };
</script>

<div class="hero w-full" style={`--y: ${y}px`}>
  <picture class="item-1 w-full fixed">
    <source srcset={image1.sm} media="(max-width: 420px)" type="image/webp" />
    <source srcset={image1.md} media="(max-width: 800px)" type="image/webp" />
    <source srcset={image1.lg} type="image/webp" />
    <img
      class="object-cover object-top w-full"
      src={image1.png}
      alt={image1.alt}
    />
  </picture>
  <div class="item-2 w-full fixed title anton">{title}</div>
  <picture class="item-3 fixed">
    <source srcset={image2.sm} media="(max-width: 420px)" type="image/webp" />
    <source srcset={image2.md} media="(max-width: 800px)" type="image/webp" />
    <source srcset={image2.lg} type="image/webp" /><img
      class="object-cover object-top w-full"
      src={image2.png}
      alt={image2.alt}
    />
  </picture>
  <picture class="item-4 w-full absolute">
    <source srcset={image3.sm} media="(max-width: 420px)" type="image/webp" />
    <source srcset={image3.md} media="(max-width: 800px)" type="image/webp" />
    <source srcset={image3.lg} type="image/webp" /><img
      class="object-cover object-top w-full"
      src={image3.png}
      alt={image3.alt}
    />
  </picture>
</div>
<div class="body relative">
  <slot name="content" />
</div>
<slot name="footer" />
<svelte:window bind:scrollY={y} />

<style>
  .item-1 {
    z-index: 1;
  }
  .hero {
    min-height: calc(50vw + 5em);
    height: 100%;
  }
  @media (max-device-width: 600px) {
    .hero {
      min-height: calc(30vh + 5em);
    }
  }
  .item-3 {
    top: 1rem;
    will-change: transform;
    transform: translateY(calc(var(--y) * -0.48));
    z-index: 3;
  }

  .item-4 {
    z-index: 4;
  }

  .title.item-2 {
    z-index: 4;
    font-size: calc(1em + 3vw);
    font-weight: bold;
    letter-spacing: 5px;
    color: #fff;
    text-shadow: 0 0 0.75rem #000;
    left: 50%;
    top: 5rem;
    text-align: center;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    will-change: transform;
    transform: translate(-50%, calc(var(--y) * 0.24));
  }

  .body {
    z-index: 4;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 50px;
    padding-bottom: 4rem;
    font-size: 50px;
    color: #fff;
    background-color: #222;
    text-shadow: 0px -30px 170px 80px #000;
  }
</style>
