<script>
  import "./menu/header.css";
  import { AppStore } from "../store";
  import { Link } from "svelte-navigator";
  import Login from "./Login.svelte";

  export let items = [];
  export let isList = false;
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  console.log(process.env.NODE_ENV, API_URL);
  const assetFolder = `${API_URL}/assets/`;

  $: items = items.filter((item) => item.location === "header");
  $: console.log(items);
</script>

{#if isList}
  <br />
{:else}
  |
{/if}

{#each items as item}
  {#if item.folder && item.path == "/"}
    <Link to={"/" + item.folder.path} title={item.title}>
      {#if !item.image}
        <span class={isList ? "list" : ""}>{item.title}</span>
      {:else}
        <img
          src={assetFolder + item.image.filename_disk}
          alt={item.image.title}
        />
      {/if}
    </Link>
    {#if isList}
      <br />
    {:else}
      |&nbsp;
    {/if}
  {:else if item.path}
    <Link
      to={(item.folder ? item.folder.path : "") + item.path}
      title={item.title}
      target={item.path.startsWith("http") ? "_blank" : undefined}
    >
      {#if !item.image}
        <span class={isList ? "list" : ""}>{item.title}</span>
      {:else}
        <img
          src={assetFolder + item.image.filename_disk}
          alt={item.image.title}
        />
      {/if}
    </Link>
    {#if isList}
      <br />
    {:else}
      |&nbsp;
    {/if}
  {:else if item.page}
    <Link
      to={(item.folder ? item.folder.path : "") + item.page.path}
      title={item.title}
    >
      {#if !item.image}
        <span class={isList ? "list" : ""}>{item.title}</span>
      {:else}
        <img
          src={assetFolder + item.image.filename_disk}
          alt={item.image.title}
        />
      {/if}
    </Link>
    {#if isList}
      <br />
    {:else}
      |&nbsp;
    {/if}
  {/if}
{/each}
{#if isList}
  {#if $AppStore.loggedIn}
    <Link to="tasks">
      <span class={isList ? "list" : ""}>Tasks</span>
    </Link>
  {/if}
  <slot />
  <br /> <br />
  <Login />
{/if}
{#if $$slots.copy}<slot name="copy" />{/if}

<style>
  .list {
    display: block;
  }
</style>
