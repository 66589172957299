<script>
  import Footer from "./Footer.svelte";
  export let image = "412e9de7-daaa-4dc1-9459-cc7694ea7c57.webp";
  export let socialIcons = [];
  export let textLinks = [];
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
</script>

<main
  style={`background-image:
  linear-gradient(
  rgba(0, 0, 0, 0.45) 100%,
  rgba(255, 255, 255, 0.25) 0%
  ), url(${API_URL}/assets/${image}`}
>
  <slot />
</main>
<footer>
  <Footer items={socialIcons} />
  <Footer items={textLinks}>
    <span slot="copy">
      | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
    </span>
  </Footer>
</footer>

<style>
  main {
    width: 100wv;
    padding-bottom: 5em;
    padding-top: 7em;
    min-height: calc(100vh - 200px);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  span {
    line-height: 1rem;
    margin: 0;
    padding: 0;
  }
</style>
