<script>
  import DirectusSDK from "@directus/sdk-js";
  const API_URL = process.env.NODE_ENV
    ? "https://www.mostest.pixelbypixelcanada.com/api"
    : "http://localhost:8055";
  const directus = new DirectusSDK(API_URL);
  import "./index.css";
  import { AppStore } from "./store";
  import Tasks from "./pages/Tasks.svelte";
  import LoginPage from "./pages/Login.svelte";
  import RouteGuard from "./components/base/RouteGuard.svelte";
  import Menu from "./components/Menu.svelte";

  import { Router, Route, Link } from "svelte-navigator";
  import ErrorMsg from "./components/base/ErrorMsg.svelte";
  import Sidebar from "./components/menu/Sidebar.svelte";
  import Navbar from "./components/menu/Navbar.svelte";
  import PageWrap from "./components/PageWrap.svelte";
  import GameWrap from "./components/GameWrap.svelte";
  import DesertHero from "./components/heroes/Desert.svelte";
  import Footer from "./components/Footer.svelte";
  import ImageHero from "./components/heroes/Image.svelte";
  import TwoHero from "./components/heroes/Two.svelte";
  import RepeatHero from "./components/heroes/Repeat.svelte";
  import ManyHero from "./components/heroes/Many.svelte";
  import Games from "./components/heroes/Games.svelte";
  import Game from "./components/heroes/Game.svelte";
  import Scores from "./pages/Scores.svelte";

  export let pages = [];
  let open = false;
  let menu = [];
  let games = [];
  let galleries = [];
  let socialIcons;
  let textLinks;

  async function getPages() {
    try {
      let { data } = await directus.axios.post("/graphql", {
        query: `query getPages {
        pages {title subtitle path content folder {path} background {filename_disk}} 
        games { id sort title subtitle html background { filename_disk } path subtitle sections { content_id { id title subtitle html background { filename_disk } } } }
        gallery { id sort title images { directus_files_id { id title description filename_disk } } }
        menu(sort: ["-sort", "date_created"]) {title location path folder {path} page {path} image {title filename_disk}} 
      }`,
      });
      if (!data) return;
      console.log(data.data);
      pages = data.data.pages ? [...pages, ...data.data.pages] : pages || [];
      menu = data.data.menu ? [...menu, ...data.data.menu] : menu || [];
      games = data.data.games ? [...games, ...data.data.games] : games || [];
      galleries = data.data.gallery
        ? [...games, ...data.data.gallery]
        : gallery || [];
      socialIcons = menu.filter((m) => m.image && m.path);
      textLinks = menu.filter((m) => !m.image);
    } catch (err) {
      console.error(err);
      AppStore.setError(err.message || err);
    }
  }
  $: getPages();
  $: firstGame = games[0];
  $: console.log({ games, socialIcons, textLinks });
</script>

<Router>
  {#if $AppStore.error}
    <div class="page lists-show">
      <Navbar bind:sidebar={open}>
        <Menu items={menu} />
      </Navbar>
      <Sidebar bind:open>
        <Menu isList={true} bind:items={menu}>
          <Link to="leaderboard">
            <span class="display-block">Leaderboard</span>
          </Link>
        </Menu>
      </Sidebar>
      <PageWrap {socialIcons} {textLinks}>
        <div class="wrapper center">
          <ErrorMsg />
        </div>
      </PageWrap>
    </div>
  {:else}
    <div class="page lists-show">
      <header>
        <Navbar bind:sidebar={open}>
          <Menu bind:items={menu} />
        </Navbar>
        <Sidebar bind:open>
          <Menu isList={true} bind:items={menu}>
            <Link to="leaderboard">
              <span class="display-block">Leaderboard</span>
            </Link>
          </Menu>
        </Sidebar>
      </header>

      <!-- likely overriden below -->
      <Route primary={false} {socialIcons} {textLinks}>
        <DesertHero />
      </Route>

      <Route path="tasks" let:params let:location let:navigate>
        <PageWrap {socialIcons} {textLinks}>
          <RouteGuard>
            <div class="wrapper">
              <h1 class="title-page">Tasks</h1>
              <div class="subtitle-message">Stuff I should probably do.</div>
            </div>
            <div class="wrapper center"><Tasks user={$AppStore.user} /></div>
          </RouteGuard>
        </PageWrap>
      </Route>
      <Route path="leaderboard" let:params let:location let:navigate>
        <PageWrap {socialIcons} {textLinks}>
          <Scores />
        </PageWrap>
      </Route>

      <Route path="login">
        <PageWrap {socialIcons} {textLinks}>
          <LoginPage />
        </PageWrap>
      </Route>

      <Route path="freedom" primary={false}>
        {#if window.location.search == "?mode=many"}
          <ManyHero title="2D Parallax">
            <footer slot="footer">
              <Footer items={socialIcons} />
              <Footer items={textLinks}>
                <span slot="copy">
                  | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
                </span>
              </Footer>
            </footer>
          </ManyHero>
        {/if}
        {#if window.location.search == "?mode=double"}
          <RepeatHero>
            <span slot="title">Marbles</span>
            <footer slot="footer">
              <Footer items={socialIcons} />
              <Footer items={textLinks}>
                <span slot="copy">
                  | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
                </span>
              </Footer>
            </footer>
            <span slot="content" />
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
          </RepeatHero>
        {/if}
        {#if window.location.search == "?mode=desert"}
          <DesertHero>
            <span slot="title">Marbles</span>
            <footer class="relative bottom w-full" slot="footer">
              <Footer items={socialIcons} />
              <Footer items={textLinks}>
                <span slot="copy">
                  | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
                </span>
              </Footer>
            </footer>
            <span slot="content" />
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
          </DesertHero>
        {/if}
        {#if window.location.search == "?mode=two"}
          <TwoHero>
            <span slot="title">Marbles</span>
            <footer slot="footer">
              <Footer items={socialIcons} />
              <Footer items={textLinks}>
                <span slot="copy">
                  | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
                </span>
              </Footer>
            </footer>
            <span slot="content" />
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
          </TwoHero>
        {/if}
        {#if !window.location.search || window.location.search == "?mode=single"}
          <ImageHero {socialIcons} {textLinks}>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
            <span slot="content">words and stuff and words</span>
          </ImageHero>
        {/if}
      </Route>

      {#each pages as page}
        <Route
          primary={false}
          path={page.title === "Pixel by Pixel Studios"
            ? undefined // undefined replaces home
            : page.folder && page.folder.path + page.path.split("?")[0]}
        >
          {#if window.location.search == "?mode=desert" || page.title === "Pixel by Pixel Studios"}
            <DesertHero
              title={page.title}
              image={page?.background?.filename_disk}
            >
              <section slot="content">
                <div class="wrapper">
                  <h1 class="title-page">{page.title}</h1>
                  <div class="subtitle-message">{page.subtitle}</div>
                </div>
                <section>{@html page.content}</section>
              </section>
              <footer slot="footer">
                <Footer items={socialIcons} />
                <Footer items={textLinks}>
                  <span slot="copy">
                    | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
                  </span>
                </Footer>
              </footer>
            </DesertHero>
          {:else if window.location.search == "?mode=double"}
            <RepeatHero title={page.title}>
              <section slot="content">
                <div class="wrapper">
                  <h1 class="title-page">{page.title}</h1>
                  <div class="subtitle-message">{page.subtitle}</div>
                </div>
                <div class="wrapper">{@html page.content}</div>
              </section>
              <footer slot="footer">
                <Footer items={socialIcons} />
                <Footer items={textLinks}>
                  <span slot="copy">
                    | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
                  </span>
                </Footer>
              </footer>
            </RepeatHero>
          {:else if window.location.search == "?mode=two"}
            <TwoHero title={page.title}>
              <section slot="content">
                <div class="wrapper">
                  <h1 class="title-page">{page.title}</h1>
                  <div class="subtitle-message">{page.subtitle}</div>
                </div>
                <div class="wrapper">{@html page.content}</div>
              </section>
              <footer slot="footer">
                <Footer items={socialIcons} />
                <Footer items={textLinks}>
                  <span slot="copy">
                    | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
                  </span>
                </Footer>
              </footer>
            </TwoHero>
          {:else if window.location.search.startsWith("?mode=")}
            <ImageHero {socialIcons} {textLinks}>
              <section slot="content">
                <div class="wrapper">
                  <h1 class="title-page">{page.title}</h1>
                  <div class="subtitle-message">{page.subtitle}</div>
                </div>
                <div class="wrapper">{@html page.content}</div>
              </section>
              <footer slot="footer">
                <Footer items={socialIcons} />
                <Footer items={textLinks}>
                  <span slot="copy">
                    | © {new Date().getFullYear()} | Pixel by Pixel Studios Inc.
                  </span>
                </Footer>
              </footer>
            </ImageHero>
          {:else}
            <PageWrap
              {socialIcons}
              {textLinks}
              image={page?.background?.filename_disk}
            >
              <div class="wrapper">
                <h1 class="title-page">{page.title}</h1>
                <div class="subtitle-message">{page.subtitle}</div>
              </div>
              <div class="wrapper">{@html page.content}</div>
            </PageWrap>
          {/if}
        </Route>
      {/each}

      <Route path="/games" primary={false}>
        <Games {socialIcons} {textLinks} {...games} sections={games} />
      </Route>
      {#each games as game}
        <Route path={"/games/" + game?.path} primary={false}>
          <Game {socialIcons} {textLinks} {...game} {galleries} />
        </Route>
      {/each}
    </div>
  {/if}
</Router>

<style>
  .page {
    z-index: 0;
  }
  header {
    width: calc(100%);
    position: fixed;
    top: 0;
    z-index: 10;
  }
  div.page {
    background-color: #111 !important;
    color: antiquewhite;
  }
  .subtitle-message {
    margin-left: 0.1em;
    font-size: large;
  }

  .page main .wrapper {
    border-color: #333;
    background-color: #333;
    display: block;
    width: 980px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 2em;
  }
</style>
