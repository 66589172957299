<script>
  import ErrorMsg from "../components/base/ErrorMsg.svelte";
  import ItemList from "../components/list/ItemList.svelte";
  import { scoreStore } from "../store";

  export let error;
  const loading = true;
  const collection = "leaderboard";
  const title = "Global Leaderboard";
  const subtitle = "The Highest of Scores";
  const options = { sort: "position" };
  const gridCols = "auto 1fr auto";
  const columns = ["Position", "Username", "Points"];
  const delay = 200;
</script>

<section class="w-auto max-w-screen-lg m-auto">
  {#if error}
    <ErrorMsg {error} />
  {:else}
    <div class="w-auto max-w-screen-lg m-auto list wrapper">
      <h1 class="title-page text-center block">{title}</h1>
      <div class="subtitle-message text-center">{subtitle}</div>
    </div>
    <div class="w-auto max-w-screen-lg m-auto list wrapper center">
      <ItemList
        {loading}
        {columns}
        {collection}
        {options}
        {delay}
        store={scoreStore}
        {gridCols}
      />
    </div>
  {/if}
</section>

<style>
  .subtitle-message {
    margin-left: 0.1em;
    font-size: large;
  }
  div.list.wrapper {
    border-color: #333;
    background-color: #333;
    display: block;
  }
</style>
